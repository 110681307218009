// background: url('../../../public/uploads/en_desktop_roulette_background.jpg') no-repeat center / cover;
.roulette-section {
  @include flexbox($justifyContent: space-evenly, $alignItems: stretch, $gap: 20px);
  @include pxToEm(padding, 10);

  position: relative;
  inset: 0;
  margin: auto;

  width: 100%;
  height: 100%;
  min-height: calc(var(--vh, 1vh) * 100);

  overflow: hidden auto;

  @media (min-width: 993px) and (orientation: landscape) {
    overflow: hidden;
  }

  @media (max-width: 992px) {
    @include pxToEm(padding, 10);

    align-items: center;
    justify-content: center;
    flex-flow: column-reverse nowrap;

    // .button-language {
    //   position: absolute;
    //   margin: 0;

    //   @include pxToEm(bottom, 10);
    //   @include pxToEm(right, 10);

    //   min-width: 160px;
    // }
  }

  @media (max-width: 992px) and (orientation: landscape) {
    position: relative;
    height: 100%;
    overflow: hidden auto;
  }

  //&__full-screen {
  //  background: var(--auth-required-bg-desktop);
  //
  //  @media(max-width: 768px) {
  //    background: var(--auth-required-bg-mobile);
  //  }
  //}

  &__content {
    @include flexbox($justifyContent: center, $alignItems: center);

    position: relative;

    width: 100%;
    max-width: max-content;

    &__chest-with-chips {
      z-index: 2;

      position: absolute;
      top: -120px;
      left: -50px;

      width: max(50%, min(50%, 240px));

      animation: moveUpDown 2s infinite;

      @media (max-width: 992px) {
        top: -25%;
        left: -10%;
      }
    }

    &__spin {
      position: relative;

      @media (max-width: 992px) and (orientation: portrait) {
        width: 90vw;
        height: 90vw;
      }

      @media (max-width: 992px) and (orientation: landscape) {
        width: 50vw;
        height: 50vw;
      }

      &__custom-wheel {
        z-index: 1;
        width: 100%;
        height: 100%;

        background: url('../../../public/images/wheel/roulette.png') no-repeat center / contain;
        padding: 8%;

        transform: rotate(-47deg);
        transform-origin: center center;

        >div {
          width: 100%;
          height: 100%;
          max-width: 100%;
          max-height: 100%;
          z-index: 1;
        }
      }

      &__button {
        cursor: pointer;

        @include flexbox($justifyContent: center, $alignItems: center);
        max-width: max(40%, min(140px, 90%));
        max-height: max(40%, min(140px, 90%));

        width: 100%;
        height: 100%;

        z-index: 2;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(47deg);

        background: transparent;
        outline: none;
        border: none;
        padding: 0;

        span {
          position: absolute;
          text-align: center;
          letter-spacing: 0.78px;
          text-transform: uppercase;

          @include pxToEm(font-size, 40);
          color: #fff;

          @media (max-width: $mobile) {
            @include pxToEm(font-size, 30);
          }

          @media (max-width: $xs) {
            @include pxToEm(font-size, 18);
          }

          @media (min-width: $small) {
            @include pxToEm(font-size, 50);
          }

          @media (min-width: $medium) {
            @include pxToEm(font-size, 60);
          }

          @media (min-width: $large) {
            @include pxToEm(font-size, 60);
          }

          @media (min-width: $medium) and (max-height: 900px) {
            @include pxToEm(font-size, 35);
          }

          @media (max-width: $small) and (max-height: 900px) {
            @include pxToEm(font-size, 25);
          }
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;

          transform: rotate(45deg);
          transform-origin: center center;

          pointer-events: none;
        }
      }
    }
  }

  &__info {
    @include flexbox($alignItems: center, $flow: column nowrap, $gap: 10px);
    width: 100%;
    max-width: max(320px, min(40%, 250px));

    margin-top: 10vh;

    @media (max-width: 992px) {
      margin-top: 0;
    }

    button {
      position: relative;
    }

    .logo {
      width: auto;
      height: auto;
      margin-left: auto;
      margin-right: auto;
      object-fit: contain;
      max-width: 75%;
      display: block;
    }

    button:first-of-type {
      align-items: flex-start;
    }

    .button-language {
      @media (max-width: 1920px) {
        .name {
          display: none;
        }
      }
    }
  }

  &__link {
    --btn--roulette-section__link-site__padding: 1.125em 2em;
    --btn--roulette-section__link-site__padding--mobile: 1.125em 2em;
    --btn--roulette-section__link-site__radius: 10px;
    --btn--roulette-section__link-site__border: 0 2px 0 rgba(0, 0, 0, 0.02);
    --btn--roulette-section__link-site__bg: rgba(22, 10, 30, 0.2);
    --btn--roulette-section__link-site__color: rgba(255, 255, 255, 0.75);

    border: 1px solid rgba(255, 255, 255, 0.22) !important;

    flex-flow: column nowrap;
    gap: 5px;

    backdrop-filter: blur(15px);
    background: rgba(22, 10, 30, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.22);
    border-radius: 10px;
    width: 100%;
    height: auto;

    span {
      &:first-of-type {
        @include font(12, 12, 700, rgba(255, 255, 255, 0.75));
      }

      &:last-of-type {
        @include font(14, 14, 700, #fff);
      }
    }

    .icon-arrow {
      position: absolute;
      right: 4px;
      top: 50%;
      transform: translateY(-50%) !important;
    }
  }

  &__chest {
    @include font(14, 14, 700, #fff);

    flex-flow: row nowrap;
    gap: 15px;

    @media (min-width: $medium) {
      justify-content: space-evenly;
    }

    span {
      &:first-of-type {
        @include font(28, 18, 300, #fff);
        @include pxToEm(margin-right, 2);
      }

      &:last-of-type {
        @include font(20, 14, 300, #fff);

        text-transform: uppercase;
      }
    }

    img {
      @media (max-width: 992px) {
        width: 40px;
        height: 40px;
      }
    }
  }

  &__win {
    width: 100%;
    opacity: 0;
    visibility: hidden;
    transition: 0.4s ease;

    .title {
      @include font(24, 16, 500, rgb(63 189 83));
      @include pxToEm(margin-bottom, 5);
    }

    &.show {
      opacity: 1;
      visibility: visible;
    }
  }

  &__bonus {
    padding: 10px 16px;
    @include flexbox($flow: column nowrap, $gap: 10px, $justifyContent: space-evenly);

    pointer-events: none;

    flex-shrink: 0;
    border-radius: 10px;
    border: var(--bonus-border);
    background: var(--bonus-background);
    box-shadow: var(--bonus-shadow);
    width: 100%;

    @media (max-width: $small) {
      @include pxToEm(height, 65);
    }

    span {
      @include font(25, 13, 500, var(--bonus-color));
      text-align: center;
      text-transform: uppercase;
      margin: 0;
    }
  }

  // Form
  .auth-form {
    margin: 0;

    &.loader-form {
      min-height: auto;
    }

    &_title {
      display: none;
    }

    &_wrapper {
      background: transparent;
      border: 0;
      padding: 0;
      border-radius: 0;
    }

    .handle-back-in-form {
      display: none;
    }
  }

  &--show-form {
    @include pxToEm(padding, 10);
    justify-content: center;

    @media (min-width: 993px) {
      overflow: hidden auto;
    }

  }

  .show-form {
    position: relative;
    margin: auto;
    max-width: 360px;
    min-width: 320px;
    width: max-content;
    opacity: 1;
    transition: all 0.2s ease-in-out;
    transform-origin: center center;

    @media (max-width: 992px) and (orientation: portrait) {
      overflow: scroll;
      padding-top: 40px;
      padding-bottom: 40px;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    @media (max-width: 992px) and (orientation: landscape) {
      margin: 20px 0 50px;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    .roulette-section__link {
      position: relative;
    }

    .roulette-section__bonus-in-form {
      pointer-events: none;
    }

    &_content {
      position: relative;
      @include pxToEm(padding, 20);
      @include pxToEm(margin, 10 0);
      background: var(--auth-forms-bg);
      border: var(--auth-forms-border);
      box-shadow: var(--auth-forms-shadow);
      border-radius: 16px;
      transition: all 0.2s ease;
    }

    .logo {
      width: auto;
      height: auto;
      margin-left: auto;
      margin-right: auto;
      object-fit: contain;
      max-width: 70%;
      display: block;
    }

    .bonus-in-form {
      width: 100%;

      .item {
        @include pxToEm(margin-bottom, 10);
        height: 45px;
        border-width: 2px;

        span {
          font-size: large;
        }

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    .title {
      @include font(22, 18, 700, var(--bonus-title-color));
      @include pxToEm(margin-bottom, 5);
      width: 100%;
      display: inline-block;
      text-align: center;

      &-win {
        @include font(24, 18, 500, var(--bonus-win-color));
        @include pxToEm(margin-bottom, 10);
        width: 100%;
        display: inline-block;
        text-align: center;
        text-transform: uppercase;
      }

      &-reg {
        @include font(14, 12, 400, #fff);
        @include pxToEm(margin, 10 0 20);
        text-align: center;
      }
    }

    .auth-form {
      margin: 0;

      &.loader-form {
        min-height: auto;
      }

      &_title {
        display: none;
      }

      &_wrapper {
        background: transparent;
        border: 0;
        padding: 0;
        border-radius: 0;
      }

      .handle-back-in-form {
        display: none;
      }
    }

    .button-language {
      @include pxToEm(margin, 10 0 0);
    }
  }

  @keyframes moveUpDown {
    0% {
      -webkit-transform: translate(-50%, 0px);
      transform: translate(-50%, 0px);
    }

    50% {
      -webkit-transform: translate(-50%, 10px);
      transform: translate(-50%, 10px);
    }

    100% {
      -webkit-transform: translate(-50%, 0px);
      transform: translate(-50%, 0px);
    }
  }
}
